import AnimatedContainer from "../../container/animatedContainer";
import { BsTicketPerforated } from "react-icons/bs";
import { CgChevronRightO } from "react-icons/cg";
import { CgChevronLeftO} from "react-icons/cg";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { GoArrowRight } from "react-icons/go";
import { HiOutlineExclamationCircle } from "react-icons/hi2";
import {useEffect, useState} from "react";
import { motion } from "framer-motion";
import { useParams} from "react-router-dom";
import {useGetSingleEventQuery} from "../../api/event";
import {Ticket} from "../../types/event";
import {usePaymentMutation} from "../../api/tickets";
import {useForm} from "react-hook-form";
import mtn_logo from "../../assets/images/mtn-logo.png";
import {useAppSelector} from "../../store";
import {PaymentPayload} from "../../types/tickets";
import {useVerifyPromoCodeMutation} from "../../api/promo_code";

type SelectedTicket = { pricing: Ticket, ticket_number: number  };

export default function EventDetails() {
    const [cerror, setCerror] = useState(false);
    const { slug } = useParams();
    const { data, isLoading  } = useGetSingleEventQuery({ slug: slug ?? '' });
    const [steps, setSteps] = useState<1 | 2 | 3>(1)
    const [selected, setSelected] = useState<SelectedTicket[]>([]);
    const [paymentMode, setPaymentMode] = useState<'momo' | 'code'>('momo');
    const [payment, payOptions] = usePaymentMutation();
    const [verify, promoOptions] = useVerifyPromoCodeMutation();
    const { user } = useAppSelector((state) => state.userState)

    const { handleSubmit, register, formState: { errors }, reset, watch, setError } = useForm({
        defaultValues: {
            email: user?.email ?? '',
            name: user?.name ?? '',
            phoneNumber: '',
            payment_mode: paymentMode,
            promoCode: null,
            personalPhoneNumber: user?.phoneNumber.substring(0, 3) ?? '',
        }
    });

    const getTicketNumber = (id: string): number => {
        const selectedTicket = selected.find(ticket => ticket.pricing.id === id);
        return selectedTicket ? selectedTicket.ticket_number : 0;
    };

    const addToSelected = (sP: Ticket) => {
        setSelected(prevSelected => {
            const existingTicket = prevSelected.find(ticket => ticket.pricing.id === sP.id);

            if (existingTicket) {
                return prevSelected.map(ticket =>
                    ticket.pricing.id === sP.id ? { ...ticket, ticket_number: ticket.ticket_number + 1 } : ticket
                );
            } else {
                return [...prevSelected, { pricing: sP, ticket_number: 1 }];
            }
        });
    };

    const removeFromSelected = (pricingIdToRemove: string) => {
        setSelected(prevSelected =>
            prevSelected.filter(ticket => ticket.pricing.id !== pricingIdToRemove)
        );
    };

    const getTotal = () => {
        let totalQ = 0;
        selected.map((g, index) => {
            totalQ = totalQ + (g.ticket_number * g.pricing.price);
            return totalQ
        })

        return totalQ;
    }

    useEffect(() => {
        setCerror(false);
    }, [steps]);

    const handlePayment = (data: any) => {
        const refined = {
            ...data,
            personalPhoneNumber: `2507${data.personalPhoneNumber}`,
            phoneNumber: `2507${data.phoneNumber}`,
            pricingIds: selected.map((it) => {
                return {
                    pricingId: it.pricing.id,
                    ticket_number: it.ticket_number,
                }
            })
        } as PaymentPayload
        payment({ status: !!user, data: refined });
        console.log(refined);
    }

    useEffect(() => {
        if(payOptions.isSuccess) {
            setSteps(1);
            setSelected([]);
            reset();
        }
    }, [payOptions.isSuccess]);

    return (
        <AnimatedContainer>
            { isLoading ? <div className={'w-full min-h-[500px] flex justify-center items-center text-center text-gray-500'} >One moment Please...</div> : null }
            { data &&
                <AnimatedContainer>
                    <div className={'min-h-[600px] flex justify-center flex-col items-start py-10 md:px-10'}>
                        <div
                            className="flex flex-col md:flex-row items-start justify-center gap-10 w-full md:w-[90%] px-3">
                            <div className="w-full md:w-4/6 bg-darker h-44 md:h-[25rem] overflow-clip rounded-3xl"
                                 style={{
                                     background: `url('${data.image}')`,
                                     backgroundSize: 'contain',
                                     backgroundRepeat: 'no-repeat',
                                     backgroundPosition: 'center',
                                     backgroundColor: '#111111'
                                 }}></div>

                            <div className="flex px-3 md:px-0 flex-col gap-2 w-full md:w-2/6">
                                <h1>Event Details</h1>
                                <h1 className={'text-2xl font-bold'}>{data.title}</h1>

                                <div className="flex flex-col gap-1">
                                    <h2 className={'text-gray-500 font-light text-sm'}>Start Time</h2>
                                    <p>{new Date(data?.date ?? '').toDateString()}</p>
                                </div>

                                <div className="flex flex-col gap-1">
                                    <h2 className={'text-gray-500 font-light text-sm'}>End Time</h2>
                                    <p>{new Date(data?.endDate ?? '').toDateString()}</p>
                                </div>

                                <div className="flex flex-col gap-1">
                                    <h2 className={'text-gray-500 font-light text-sm'}>Location</h2>
                                    <p>{data?.location}</p>
                                </div>

                                <div className="flex flex-col gap-1">
                                    <h2 className={'text-gray-500 font-light text-sm'}>Status</h2>
                                    <p>{data?.status === 'active' ? 'Active' : 'Inactive'}</p>
                                </div>
                            </div>
                        </div>
                        <div className="flex mt-3 flex-col md:flex-row items-start justify-start gap-10 w-[100%] px-3">
                            <div className=" w-full items-center flex-col flex md:w-[60%] min-h-[400px]">
                                <div className="py-3 text-2xl font-bold px-3 w-full">Available Tickets</div>
                                {cerror && <motion.div
                                    initial={{y: '-10px', opacity: 0.5}}
                                    animate={{y: 0, opacity: 1}}
                                    transition={{duration: 0.5}}
                                    className="py-1 border-l-2 flex flex-row gap-2 items-center w-full mb-2 px-2  border-red-500 bg-red-200 text-red-500">
                                    <HiOutlineExclamationCircle/>
                                    Please select select tickets
                                </motion.div>}
                                {payOptions.isSuccess && <motion.div
                                    initial={{y: '-10px', opacity: 0.5}}
                                    animate={{y: 0, opacity: 1}}
                                    transition={{duration: 0.5}}
                                    className="py-1 border-l-2 flex flex-row gap-2 items-center w-full mb-2 px-2  border-green-500 bg-green-50/10 text-green-700">
                                    <HiOutlineExclamationCircle/>
                                    Payment Initiated!.
                                </motion.div>}
                                {payOptions.isError && <motion.div
                                    initial={{y: '-10px', opacity: 0.5}}
                                    animate={{y: 0, opacity: 1}}
                                    transition={{duration: 0.5}}
                                    className="py-1 text-sm border-l-2 flex flex-row gap-2 items-center w-full mb-2 px-2  border-red-500 bg-red-50/10 text-red-700">
                                    <HiOutlineExclamationCircle/>
                                    {(payOptions.error ? (payOptions.error as any).data?.message : 'Something went wrong, Payment Failed' )}
                                </motion.div>}
                                {steps === 1 &&
                                    <>
                                        <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-3">
                                            {data.pricings.length === 0 ? <div
                                                className={'col-span-2 text-center min-h-32 items-center flex justify-center'}>No
                                                Tickets Yet</div> : ''}
                                            {data.pricings.map((item, index) => <div
                                                key={index}
                                                className="p-3 flex rounded-lg flex-wrap items-center justify-between gap-5 bg-[#F9F9F9]">
                                                <div className="flex flex-row gap-4 items-center">
                                                    <div
                                                        className="p-2 text-2xl bg-[#EEF6FC] rounded-lg text-[#085093]">
                                                        <BsTicketPerforated/></div>
                                                    <div className="flex capitalize font-semibold flex-col gap-1">
                                                        <p>{item.type}</p>
                                                        <p><b>{item.price.toLocaleString()}</b> {data.currency}</p>
                                                    </div>
                                                </div>
                                                <div className="flex flex-row items-center text-gray-500">
                                                    <button onClick={() => removeFromSelected(item.id)}
                                                            className="p-2 text-[1.3rem]  rounded-l cursor-pointer ">
                                                        <CgChevronLeftO/>
                                                    </button>
                                                    <div
                                                        className="p-2 px-3 rounded-lg mx-2 text-[1rem] bg-gray-100 cursor-pointer text-[#085093]">
                                                        {getTicketNumber(item.id)}
                                                    </div>
                                                    <button onClick={() => addToSelected(item)}
                                                            className="p-2 text-[1.3rem] rounded-r cursor-pointer ">
                                                        <CgChevronRightO/>
                                                    </button>
                                                </div>
                                            </div>)}
                                        </div>
                                        <div onClick={() => selected.length === 0 ? setCerror(true) : setSteps(2)}
                                             className="py-3 cursor-pointer mt-4 bg-primary col-span-3 gap-3 text-white rounded-full flex justify-center items-center w-56">
                                            Buy Tickets <GoArrowRight className={'text-2xl mt-0.5'}/>
                                        </div>
                                    </>
                                }
                                { steps === 2 &&
                                    <>
                                        <div className="w-full flex flex-col gap-3 my-3">
                                            <div className={'py-3 flex flex-row gap-2 items-center'}>
                                                <IoArrowBackCircleOutline onClick={() => setSteps(1)}
                                                                          className={'cursor-pointer'}/> Summary
                                            </div>
                                            <div className="border-b">
                                                {selected.map((sel, index) => <div
                                                    className={'flex flex-row justify-between mb-2'}>
                                                    <div className={'flex font-semibold flex-col'}>
                                                        {sel.pricing.type}
                                                        <p className={'text-sm font-semibold text-primary'}>{sel.ticket_number} Ticket{sel.ticket_number > 1 ? 's' : ''}</p>
                                                    </div>
                                                    <p>{sel.pricing.price * sel.ticket_number} {data.currency}</p>
                                                </div>)}
                                            </div>
                                            <div className={'flex flex-row font-semibold justify-between mb-2'}>
                                                <h3>Total Amount</h3>
                                                <p>{getTotal()} {data.currency}</p>
                                            </div>
                                        </div>
                                        <div onClick={() => setSteps(3)}
                                             className="py-3 cursor-pointer mt-4 bg-primary col-span-3 gap-3 text-white rounded-full flex justify-center items-center w-56">
                                            Checkout <GoArrowRight className={'text-2xl mt-0.5'}/>
                                        </div>
                                    </>
                                }

                                { steps === 3 &&
                                    <form className={'w-full flex flex-col items-center'} onSubmit={handleSubmit(handlePayment)}>
                                        <div className="w-full flex flex-col my-3">
                                            <div className={'py-3 flex flex-row gap-2 items-center'}>
                                                <IoArrowBackCircleOutline onClick={() => setSteps(2)}
                                                                          className={'cursor-pointer'}/> Checkout
                                            </div>
                                            <div className="py-4 w-full grid gap-3 grid-cols-1 sm:grid-cols-2">
                                                <div className="flex flex-col gap-2 col-span-2">
                                                    <label htmlFor="email">Email</label>
                                                    <input type="email"
                                                           {...register('email', { required: 'Email is required' })}
                                                           className={'bg-gray-100 px-3 py-2 w-full focus:outline-none rounded'}
                                                           placeholder={'Enter your email address'}/>
                                                    { errors.email && <p className={'text-red-500 text-sm'}>{errors.email.message}</p>}
                                                </div>
                                                <div className="flex flex-col gap-2 col-span-2 sm:col-span-1">
                                                    <label htmlFor="email">Names</label>
                                                    <input type="name"
                                                           {...register('name', { required: 'Name is required' })}
                                                           className={'bg-gray-100 px-3 py-2 w-full focus:outline-none rounded'}
                                                           placeholder={'Enter your full names'}/>
                                                    { errors.name && <p className={'text-red-500 text-sm'}>{errors.name.message}</p>}
                                                </div>
                                                <div className="flex flex-col gap-2 col-span-2 sm:col-span-1">
                                                    <label htmlFor="email">Phone number</label>
                                                    <div className="bg-gray-100 flex flex-row gap-1 px-3 py-2 w-full">
                                                        <p>+2507</p>
                                                        <input type="tel"
                                                               {...register('personalPhoneNumber', {
                                                                   required: 'Phone number is required',
                                                                   pattern: {
                                                                       value: /^[0-9]{8}$/,
                                                                       message: 'Phone number invalid'
                                                                   }
                                                               })}
                                                               className={'bg-transparent w-[80%]     focus:outline-none rounded'}
                                                               placeholder={'Enter your phone number'}/>
                                                    </div>
                                                    {errors.personalPhoneNumber &&
                                                        <p className={'text-red-500 text-sm'}>{errors.personalPhoneNumber.message}</p>}
                                                </div>
                                                <div
                                                    className="border-y hidden py-3 col-span-2 justify-center items-center">
                                                    <div className="flex flex-col gap-2 col-span-2 sm:col-span-1">
                                                        <label htmlFor="email">Promo Code <span className={'text-primary text-xs'}>(optional)</span></label>
                                                        <div className="flex flex-row gap-1">
                                                            <input type="name"
                                                                   {...register('promoCode')}
                                                                   className={'bg-gray-100 px-3 py-2 w-full focus:outline-none rounded'}
                                                                   placeholder={'Enter a promo code'}/>
                                                            <div onClick={() => watch('promoCode') ? verify({ promo: watch('promoCode') ?? '' }) : setError('promoCode', { message: 'Promo code is required'})} className={'bg-primary cursor-pointer text-white p-2 rounded'}>
                                                                {promoOptions.isLoading ? 'Loading' : 'Verify'}
                                                            </div>
                                                        </div>
                                                        {errors.promoCode &&
                                                            <p className={'text-red-500 text-sm'}>{errors.promoCode.message}</p>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-span-2 flex justify-center items-center">
                                                    <img src={mtn_logo} className={'w-20'} alt={'mtn-logo'}/>
                                                </div>
                                                <div className="flex flex-col col-span-2 gap-2">
                                                    <label htmlFor="email">Momo Phone number</label>
                                                    <div className="bg-gray-100 flex flex-row gap-1 px-3 py-2 w-full">
                                                        <p>+2507</p>
                                                        <input type="tel"
                                                               {...register('phoneNumber', {
                                                                   required: 'Momo phone number is required',
                                                                   pattern: {
                                                                       value: /^[0-9]{8}$/,
                                                                       message: 'Phone number invalid'
                                                                   }
                                                               })}
                                                               className={'bg-transparent w-[80%]     focus:outline-none rounded'}
                                                               placeholder={'Enter your momo phone number'}/>
                                                    </div>
                                                    { errors.phoneNumber && <p className={'text-red-500 text-sm'}>{errors.phoneNumber.message}</p>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="hidden flex-row gap-1 text-xs items-center text-center">
                                            <input name={'terms'} type="checkbox" color={'red'} />
                                            <label htmlFor="terms">
                                                By booking an event at Stage One,
                                                you acknowledge that you have read,
                                                understood, and agreed to abide by these {' '}
                                                <a href={'/terms-and-conditions'} target={'_blank'} className={'text-primary underline'} rel="noreferrer">
                                                    Terms and Conditions.
                                                </a>
                                            </label>
                                        </div>
                                        <button
                                            disabled={payOptions.isLoading}
                                            className="py-3 disabled:bg-gray-300 cursor-pointer mt-4 bg-primary col-span-3 gap-3 text-white rounded-full flex justify-center items-center w-56">
                                            {payOptions.isLoading ? 'Loading...' : 'Pay Now'}
                                        </button>
                                    </form>
                                }

                            </div>
                        </div>
                    </div>
                </AnimatedContainer>
            }
        </AnimatedContainer>
    )
}
