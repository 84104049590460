import { PiTicket } from "react-icons/pi";
import {useGetEventsQuery} from "../../api/event";
import {Link} from "react-router-dom";

export default function NextEvents() {
    const { data, isLoading } = useGetEventsQuery({ pageSize: 2 });
    return (
        <div className="w-full text-black flex-col flex gap-5">
            {isLoading && [1,1].map((item, index) => <div key={index} className="w-full h-48 border rounded-xl bg-gray-100 animate-pulse"></div>)}
            {data && data.list.filter((e) => e.id !== "d524b60d-cea7-4a7b-9926-49007dbd0152").map((item, index) => <EventCard key={index} slug={item.slug} image={item.image} title={item.title} status={item.status === 'active'} date={new Date(item.date).toLocaleDateString('en-US', {
                weekday: 'long', // 'Saturday'
                day: '2-digit', // '14'
                month: 'short', // 'Oct'
                year: 'numeric' // '2012'
            })} time={new Date(item.date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', timeZone: 'UTC' })}  />)}
        </div>
    )
}

function EventCard({ title,time,date,status, slug, image } : { title: string, slug: string, date: string, time: string, status: boolean, image: string }) {
    return (
        <div className="p-4 bg-white rounded-2xl flex flex-row gap-3">
            <div className="sm:w-2/5 bg-yellow-600 h-28 sm:h-auto w-full rounded-2xl" style={{
                background: `url('${image}')`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center'
            }}></div>

            <div className="flex flex-col w-full sm:w-3/5">
                <h2 className={'font-bold'}>{title}</h2>
                <p className={'text-gray-400'}>{date}</p>
                <div className="w-full flex flex-wrap justify-between">
                    <div className="flex flex-col gap-0 w-1/2">
                        <p className={'text-gray-400'}>Time</p>
                        <div className="font-semibold">{time}</div>
                    </div>

                    <div className="flex flex-col gap-0 w-1/2">
                        <p className={'text-gray-400'}>Status</p>
                        <div className="font-semibold">{status ? 'Active' : 'Inactive'}</div>
                    </div>
                </div>

                <div className="flex justify-center items-center mt-3">
                    <Link to={`/event/${slug}`} className={'bg-primary flex justify-center items-center gap-2 p-2 py-3 text-white w-full rounded-full'}>
                        <PiTicket /> Book Ticket
                    </Link>
                </div>
            </div>
        </div>
    )
}
