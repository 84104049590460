import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { useState } from "react";
import {useGetEventsQuery} from "../../api/event";
import {useNavigate} from "react-router-dom";

export default function HomeSlide() {
    const { data, isLoading } = useGetEventsQuery({ pageSize: 3 });
    const navigate = useNavigate();
    const [selectedIndex, setSelectedIndex] = useState(0);
    return (
        <div className="w-full">
            <div className="w-full rounded-3xl overflow-clip">
                {isLoading && <div className={'w-full h-44 sm:h-96 rounded-3xl bg-gray-50 border animate-pulse'}></div>}
                {data ? <Carousel interval={5000} autoPlay={true} onChange={(index) => setSelectedIndex(index)}
                                  showThumbs={false} emulateTouch={true} infiniteLoop={true} showStatus={false}
                                  showIndicators={false}>
                    {data.list.filter((e) => e.id !== "d524b60d-cea7-4a7b-9926-49007dbd0152").map((item, index) => (
                        <div onClick={() => navigate(`/event/${item.slug}`)} key={index} className={'w-full h-44 sm:h-96 rounded-3xl'} style={{
                            background: `url(${item.image})`,
                            backgroundSize: 'contain',
                            backgroundColor: '#111111',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat'
                        }}>
                        </div>
                    ))}
                </Carousel> : isLoading ? null : <div className={'w-full h-44 sm:h-96 rounded-3xl bg-gray-50 border animate-pulse'}></div>}
            </div>
            <div className="flex flex-row gap-1 justify-end mt-3">
            {data && data.list.filter((e) => e.id !== "d524b60d-cea7-4a7b-9926-49007dbd0152").map((_, index) => (
                    <div key={index} className={`rounded-full py-1.5 px-2 ${selectedIndex === index ? 'w-12 bg-primary' : 'w-6 bg-gray-300 '}`}>
                    </div>
                ))}
            </div>
        </div>
    )
}
